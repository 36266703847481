import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Marquee from 'react-fast-marquee';
import NodeIcon from '../Assets/Images/Node_logo_NodeJS.png';
// import ReactIcon from '../Assets/Images/react.png'
import ElementUIIcon from '../Assets/Images/ElementUIIcon.png'
import FlutterIcon from '../Assets/Images/FlutterIcon.png'
import AngularIcon from '../Assets/Images/angular.png'
import VueIcon from '../Assets/Images/vue.png';
import OrgReactIcon from '../Assets/Images/OrgReactIcon.png';
import NuxtIcon from '../Assets/Images/NuxtIcon.png';
import Tailwind from '../Assets/Images/TailwindIcon.png';
import Bootstrap from '../Assets/Images/BootstrapIcon.png';
import './Technology.css'
const Technology = ({ id }) => {
  return (
    <Box
      id={id}
      sx={{
        // padding: '20px',
        paddingTop:'1%',
        paddingBottom:'2%',
        minHeight: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F1F1F1',
      }}
    >
      <Typography  gutterBottom  id='TechName' sx={{fontWeight:'bold'}}> 
        Technology Stack
      </Typography>
      <Marquee gradient={false} speed={40} direction="left" style={{marginTop:'50px'}} pauseOnHover>
        <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
          <img
            src={OrgReactIcon}
            alt="React"
            style={{ width: 'auto', height: '80px', marginRight: '20px' }}
          />
          <Typography variant="h5">React</Typography>
        </Box>
        <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
          <img
            src={AngularIcon}
            alt="React"
            style={{ width: 'auto', height: '80px', marginRight: '20px' }}
          />
          <Typography variant="h5">Angular Js</Typography>
        </Box>
        <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
          <img
            src={VueIcon}
            alt="React"
            style={{ width: 'auto', height: '80px', marginRight: '20px' }}
          />
          <Typography variant="h5">Vue Js</Typography>
        </Box>
        <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
          <img
            src={Tailwind}
            alt="React"
            style={{ width: 'auto', height: '80px', marginRight: '20px' }}
          />
          <Typography variant="h5">Tailwind Css</Typography>
        </Box>
        <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
          <img
            src={Bootstrap}
            alt="React"
            style={{ width: 'auto', height: '80px', marginRight: '20px' }}
          />
          <Typography variant="h5">Bootstrap</Typography>
        </Box>
        <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
          <img
            src={NuxtIcon}
            alt="React"
            style={{ width: 'auto', height: '80px', marginRight: '20px' }}
          />
          <Typography variant="h5">Nuxt Js</Typography>
        </Box>
        <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
          <img
            src={NodeIcon}
            alt="Node"
            style={{ width: 'auto', height: '80px', marginRight: '20px' }}
          />
          <Typography variant="h5">Node Js</Typography>
        </Box>
        <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
          <img
            src={FlutterIcon}
            alt="Flutter"
            style={{ width: 'auto', height: '80px', marginRight: '20px' }}
          />
          <Typography variant="h5">Flutter</Typography>
        </Box>
        <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
          <img
            src={ElementUIIcon}
            alt="Android Studio"
            style={{ width: 'auto', height: '80px', marginRight: '20px' }}
          />
          <Typography variant="h5">ElementUI</Typography>
        </Box>
      </Marquee>
    </Box>
  );
};

export default Technology;
