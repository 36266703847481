import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import logo from '../Assets/Images/logo.png';

const pages = ['Home', 'Technology', 'Careers', 'About Us'];

function NavBar({ onCareersClick }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleOpenMobileMenu = () => {
    setMobileMenuOpen(true);
  };

  const handleCloseMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleScrollToSection = (id) => {
    if (id === 'careers') {
      onCareersClick();
    } else {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }
    handleCloseMobileMenu();
  };

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: 'black', height: "11vh", paddingTop: '1vh' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <img src={logo} alt="logo" style={{ width: 'auto', height: '40px', marginRight: '10px' }} />
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.2rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                LucusAI
              </Typography>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => handleScrollToSection(page.toLowerCase().replace(' ', '-'))}
                  sx={{ color: 'white' }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="open mobile menu"
                onClick={handleOpenMobileMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={handleCloseMobileMenu}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            width: 250,
            height: '100%',
            backgroundColor: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 8,
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 10, right: 10, color: 'white' }}
            onClick={handleCloseMobileMenu}
          >
            <CloseIcon />
          </IconButton>
          {pages.map((page) => (
            <Button
              key={page}
              onClick={() => handleScrollToSection(page.toLowerCase().replace(' ', '-'))}
              sx={{
                color: 'white',
                fontSize: '18px',
                my: 1,
                py: 1,
                width: '80%',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  transform: 'scale(1.05)',
                },
              }}
            >
              {page}
            </Button>
          ))}
        </Box>
      </Drawer>
    </>
  );
}

export default NavBar;
