import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { YouTube, GitHub } from "@mui/icons-material";
import "./Technology.css";
import LucusFooter from "../Assets/Images/LucusFooter.jpg";
import GoogleBadge from "../Assets/Images/Googlebadge.png"; // Add the path to your Google Play badge image

const Footer = () => {
  const theme = useTheme();
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "black",
        color: "white",
        padding: "10px 20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "56px",
        }}
      >
        <img alt="logo" height="auto" width="110px" src={LucusFooter} />
        <Box sx={{ display: "flex" }}>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FacebookIcon id="iconSize" sx={{ color: "#fff", "&:hover": { color: "#4267B2" } }} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <TwitterIcon id="iconSize" sx={{ color: "#fff", "&:hover": { color: "#1DA1F2" } }} />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <InstagramIcon id="iconSize" sx={{ color: "#fff", "&:hover": { color: "#E1306C" } }} />
          </a>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
            <YouTube id="iconSize" sx={{ color: "#fff", "&:hover": { color: "#FF0000" } }} />
          </a>
          {/* <a href="https://github.com" target="_blank" rel="noopener noreferrer">
            <GitHub id="iconSize" sx={{ color: "#fff", "&:hover": { color: "blue" } }} />
          </a> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
      >
        <Typography variant="body2" color="inherit" sx={{ fontFamily: "monospace" }}>
          Email:<a href="lucusartificialintelligence@gmail.com" style={{ color: "white" }}>lucusartificialintelligence@gmail.com</a>
        </Typography>
        <Typography
          variant="body2"
          color="inherit"
          sx={{ fontFamily: "monospace" }}
          id="fooText"
        >
          © 2024 LucusAI.All rights reserved.
        </Typography>
        <a
          href="https://play.google.com/store/apps/dev?id=6577792447188759192"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={GoogleBadge}
            alt="Get it on Google Play"
            style={{ width: "120px", height:'auto'}}
          />
        </a>
      </Box>
    </Box>
  );
};

export default Footer;