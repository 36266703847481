import React, { useState, useEffect, useRef } from 'react';
import { Button, IconButton, Box, Typography, Modal } from '@mui/material';
import NavBar from './NavBar';
import Home from './Home';
import Technology from './Technology';
import Footer from './Footer';
import AboutUs from './AboutUs';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Container = () => {
  const [isCareersModalOpen, setIsCareersModalOpen] = useState(false);
  const [isScrollTopVisible, setIsScrollTopVisible] = useState(false);
  const mainContentRef = useRef(null);

  const handleOpenCareersModal = () => {
    setIsCareersModalOpen(true);
  };

  const handleCloseCareersModal = () => {
    setIsCareersModalOpen(false);
  };

  const scrollToTop = () => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (mainContentRef.current) {
        const scrollTop = mainContentRef.current.scrollTop;
        // console.log('scrollTop:', scrollTop); // Debugging log
        if (scrollTop > 350) {
          setIsScrollTopVisible(true);
        } else {
          setIsScrollTopVisible(false);
        }
      }
    };

    const current = mainContentRef.current;
    if (current) {
      current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  console.log('isScrollTopVisible:', isScrollTopVisible); // Debugging log

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden', // Hide overflow to control scrolling within the container
      }}
    >
      <Box sx={{ height: '11vh' }}>
        <NavBar onCareersClick={handleOpenCareersModal} />
      </Box>
      <Box
        ref={mainContentRef}
        sx={{
          height: '85vh', // Remaining height for the main content
          flexGrow: 1,
          overflowY: 'auto', // Make the main content area scrollable
        }}
      >
        <Home id="home" />
        <Technology id="technology" />
        <AboutUs id="about-us" />
        <Footer />
        {/* Render other sections as needed */}
      </Box>
      <Modal
        sx={{ borderRadius: '5px' }}
        open={isCareersModalOpen}
        onClose={handleCloseCareersModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseCareersModal}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <SettingsIcon sx={{ fontSize: 40, color: 'gray' }} />
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            We are working on this
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            This feature is under development ❗❗
          </Typography>
          <Button
            onClick={handleCloseCareersModal}
            variant="contained"
            color="error"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
      {isScrollTopVisible && (
        <IconButton
          onClick={scrollToTop}
          sx={{
            position: 'fixed',
            bottom: 130,
            right: 30,
            bgcolor: 'black',
            color: 'white',
            '&:hover': {
              bgcolor: 'primary.dark',
            },
            zIndex: 2000, // Ensure the button is above other elements
          }}
        >
          <ArrowUpwardIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default Container;
