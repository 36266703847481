import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FlagIcon from "@mui/icons-material/Flag";
import LucusIcon from "../Assets/Images/lucus.jpg";
import "./Technology.css";

// const gradientAnimation = keyframes`
//   0% { background-position: 0% 50%; }
//   50% { background-position: 100% 50%; }
//   100% { background-position: 0% 50%; }
// `;

const StyledBox = styled(Box)({
  backgroundColor: "#313131",
  backgroundIimage:
    "radial-gradient(rgba(255, 255, 255, 0.171) 2px, transparent 0)",
  backgroundSize: "30px 30px",
  backgroundPosition: "-5px -5px",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const AnimatedCard = styled(Card)({
  height: "100%",
  transition: "all 0.3s ease-in-out",
  background: "rgba(255, 255, 255, 0.9)", // Increased opacity for better text readability
  borderRadius: "7px",
  overflow: "hidden",
  boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
  "&:hover": {
    transform: "translateY(-8px) scale(1)",
    boxShadow: "0 12px 30px 0 rgba(235, 235, 235, 0.5)",
  },
});

const CardHeader = styled(Box)({
  background: "#252627;",
  color: "#ffffff",
  padding: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledAvatar = styled(Avatar)({
  backgroundColor: "#dc004e",
  width: "56px",
  height: "56px",
  marginRight: "16px",
});

const AboutUs = ({ id }) => {
  return (
    <StyledBox sx={{ padding: "2%" }} id={id}>
      <Container maxWidth="lg" sx={{ borderRadius: "1px" }}>
        <Typography
          variant="h2"
          gutterBottom
          id="TechName"
          align="center"
          sx={{ color: "white", fontWeight: "bold", mb: 6 }}
        >
          About Us
        </Typography>

        <AnimatedCard sx={{ mb: 6 }}>
          <CardContent>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    height: 200,
                    borderRadius: 2,
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <img
                    src={LucusIcon}
                    alt="About Us"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fit",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="h4" gutterBottom color="#252627">
                  <b> Welcome to LucusAI</b>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ textAlign: "justify" }}
                >
                  &emsp;&emsp;&emsp;Founded in April 2024, LucusAI is a
                  forward-thinking startup committed to revolutionizing the tech
                  industry. Our initial focus is on developing innovative
                  Android and web applications designed to enhance user
                  experiences and streamline operations for businesses. At
                  LucusAI, we aspire to explore and excel in every technological
                  domain Our dedicated team of experts is passionate about
                  pushing the boundaries of what’s possible, continuously
                  seeking out new challenges and opportunities for growth.
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  &emsp;&emsp;&emsp;With a focus on artificial intelligence,
                  machine learning, and data analytics, we're at the forefront
                  of the digital revolution, helping businesses and individuals
                  harness the power of technology to achieve their goals.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </AnimatedCard>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <AnimatedCard>
              <CardHeader>
                <StyledAvatar>
                  <VisibilityIcon />
                </StyledAvatar>
                <Typography variant="h5">
                  <b>Our Vision</b>
                </Typography>
              </CardHeader>
              <CardContent>
                <Typography variant="body1" component="div">
                  🔹To become a global leader in AI-driven innovation
                  <br />
                  🔹To explore and master emerging technological frontiers
                  <br />
                  🔹To create a future where our technologies seamlessly enhance
                  human potential
                </Typography>
              </CardContent>
            </AnimatedCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <AnimatedCard>
              <CardHeader>
                <StyledAvatar>
                  <FlagIcon />
                </StyledAvatar>
                <Typography variant="h5">
                  <b>Our Mission</b>
                </Typography>
              </CardHeader>
              <CardContent>
                <Typography variant="body1" component="div">
                  🔹To develop AI solutions that solve complex real-world
                  problems
                  <br />
                  🔹To foster a culture of continuous learning and innovation
                  <br />
                  🔹To empower businesses and individuals with transformative
                  technologies
                </Typography>
              </CardContent>
            </AnimatedCard>
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
};

export default AboutUs;
