import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { TypeAnimation } from 'react-type-animation';
import styled, { keyframes } from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './Home.css'
const bubbleLineAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const AnimatedBox = styled(Box)`
  background: linear-gradient(270deg, rgba(255, 0, 0, 0.5), rgba(0, 255, 0, 0.5), rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5));
  background-size: 400% 400%;
  animation: ${bubbleLineAnimation} 10s ease infinite;
  padding: 20px;
  min-height: 85vh;
  display: flex;
  align-items: center;
`;

const Home = ({ id }) => {
  return (
    <AnimatedBox id={id}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h2" gutterBottom>
            Welcome to <span><button  class="button" data-text="Awesome">
              <span class="actual-text">&nbsp;LucusAI&nbsp;</span>
              <span aria-hidden="true" class="hover-text">
                &nbsp;LucusAI&nbsp;
              </span>
            </button></span>
          </Typography>
          <Typography variant="h4" >
            We are
          </Typography>
          <TypeAnimation
         
            sequence={[
              'Innovating',
              1000,
              'Transforming',
              1000,
              'Revolutionizing',
              1000,
              'Empowering',
              1000,
            ]}
            wrapper="span"
            speed={50}
            style={{ fontSize: '2em', display: 'inline-block',fontFamily:"monospace" }}
            repeat={Infinity}
          />
          <Typography variant="h4" gutterBottom>
            the future with AI
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
        <Carousel 
        showArrows={false}
        showIndicators={true}
        showThumbs={false}
        showStatus={false}
        autoPlay
        >
          <div>
          <img
            src="/Home.png" // Adjust the path if needed
            alt="AI Animation"
            style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }}
          />
          </div>
          <div>
          <img
            src="/Home.png" // Adjust the path if needed
            alt="AI Animation"
            style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }}
          />
          </div>
          </Carousel>
        </Grid>
      </Grid>
    </AnimatedBox>
  );
};

export default Home;
